import React from "react";
import ReactHtmlParser from "react-html-parser";

const About = ({ campaign }) => {
  if (!campaign) {
    return false;
  }

  return (
    <section className="about">
      <div className="container">
        <div className="project_content">
          {ReactHtmlParser(campaign.description)}
        </div>
      </div>
    </section>
  );
};

export default About;
