import React, { useEffect, useState } from "react";
import { Portfolio } from "../components/Portfolio";

export const PortfolioContainer = ({ campaign }) => {
  const [projects, updateCampaign] = useState({});

  useEffect(() => {
    updateCampaign(campaign);
  }, [campaign]);

  const parseDescription = (campaign) => {
    campaign.forEach(function (project) {
      project.crew = project.description
        .split("\n")
        .reduce(function (acc, cur, index) {
          acc.push(cur.trim());
          return acc;
        }, []);
    });

    return campaign;
  };

  const parseProjectTitle = (txt = "") => {
    let arr = txt.split("|");
    let title = arr[0].toUpperCase().trim();
    title = title.length > 20 ? title.substring(0, 20) + "..." : title;
    return title;
  };

  const parseProjectDescription = (txt = "") => {
    let arr = txt.split("|");
    let desc =
      arr.length > 0 && typeof arr[1] !== "undefined"
        ? '"' + arr[1].trim() + '"'
        : "";
    return desc;
  };

  return (
    <Portfolio
      projects={projects}
      parseProjectTitle={parseProjectTitle}
      parseProjectDescription={parseProjectDescription}
    />
  );
};
