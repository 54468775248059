import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ScrollableAnchor from "react-scrollable-anchor";
import { PortfolioContainer } from "./containers/PortfolioContainer";
import Navbar from "./components/Navbar";
import BackgroundVideo from "./components/BackgroundVideo";
import MonsterHeader from "./components/MonsterHeader";
import About from "./components/About";
import Footer from "./components/Footer";
import backgroundVideoList from "./data/backgroundVideos";
import { BrowserRouter as Router, Route } from "react-router-dom";
import styled from "styled-components";
import { shuffle } from "lodash";

const Container = styled.div`
  text-align: center;
`;
const ContainerTop = styled.div`
  text-align: center;
  height: 1080px;
`;

function App() {
  const [campaign, updateCampaign] = useState({});

  const handleIframeTask = (e) => {
    console.log(e.origin);
    if (
      e.origin !== "https://master.d10zymsi7a6q8i.amplifyapp.com" &&
      e.origin !== "https://router-refactor.d10zymsi7a6q8i.amplifyapp.com" &&
      e.origin !== "https://yababam.com" &&
      e.origin !== "https://master.yababam.com" &&
      e.origin !== "https://www.yababam.com" &&
      e.origin !== "https://master.ocloudio.com" &&
      e.origin !== "https://ocloudio.com" &&
      e.origin !== "https://www.ocloudio.com" &&
      e.origin !== "http://localhost:3000"
    ) {
      return;
    }
    if (e.data && e.data.id !== "") {
      updateCampaign(JSON.parse(e.data));
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleIframeTask);
  }, []);

  return (
    <Router>
      <Container>
        {isMobile === false && ( // BROWSER VIEW
          <ContainerTop>
            <Navbar campaign={campaign} />

            <BackgroundVideo
              backgroundVideoUrls={shuffle(backgroundVideoList)}
            />

            <MonsterHeader campaign={campaign} />
          </ContainerTop>
        )}
        {
          isMobile === true && <Navbar campaign={campaign} /> // MOBILE VIEW
        }
        <ScrollableAnchor id={"portfolio-monster"}>
          <p>&nbsp;</p>
        </ScrollableAnchor>
        <PortfolioContainer campaign={campaign} />
        <ScrollableAnchor id={"about"}>
          <div>
            <About campaign={campaign} />
          </div>
        </ScrollableAnchor>
        <ScrollableAnchor id={"contact"}>
          <Footer campaign={campaign} />
        </ScrollableAnchor>
      </Container>
    </Router>
  );
}

export default App;
