import React from "react";

const MonsterHeader = ({ campaign }) => {
  if (!campaign.file) {
    return false;
  }

  return (
    <header className="masthead" role="presentation">
      <div className="loading-fadein header-content">
        <div className="header-content-inner">
          {campaign.file.url ? (
            <div style={{ marginTop: "250px" }}>
              <img src={campaign.file.url} />
              <p />
              <a className="btn btn-primary btn-xl" href="#portfolio-monster">
                SHOWCASE
              </a>
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
    </header>
  );
};

export default MonsterHeader;
