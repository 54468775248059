/* eslint-disable no-useless-constructor */
import React, { Component } from 'react'
import { DefaultPlayer as Video } from 'react-html5video'
import 'react-html5video/dist/styles.css'
import PropTypes from 'prop-types'
// import { bindActionCreators } from 'redux'
// import { connect } from 'react-redux'
// import * as backgroundVideoActions from 'actions/backgroundVideo'

class BackgroundVideo extends Component {
  // static propTypes = {
  //   updateBackgroundVideo: PropTypes.func.isRequired,
  // }

  constructor(props) {
    super(props)

    this.videoPlayer = null

    this.setVideoPlayerRef = element => {
      this.videoPlayer = element
    }
    this.state = {
      counter: 0,
    }
  }

  componentDidMount() {
    //this.updateBackgroundVideo()
    this.videoPlayer.videoEl.src = `https://monstermusic.tv/video/ash.m4v`
    //this.videoPlayer.videoEl.play()
  }

  async updateVideoUrl() {
    // const updatedCounter = this.state.counter < this.props.backgroundVideoUrls.length - 1 ? this.state.counter + 1 : 0
    // await this.setState({
    //   counter: updatedCounter,
    // })
    this.videoPlayer.videoEl.src = `https://monstermusic.tv/video/ash.m4v`
    // this.videoPlayer.videoEl.src = `/video/${this.props.backgroundVideoUrls[updatedCounter].name}`
    // this.videoPlayer.videoEl.play()
    //this.updateBackgroundVideo()
  }

  updateBackgroundVideo = () => {
    const currentVimeoVideoId = this.props.backgroundVideoUrls[this.state.counter].id
    this.props.updateBackgroundVideo(currentVimeoVideoId)
  }

  render() {
    return (
      <Video
        id="video-background"
        autoPlay={true}
        muted
        onEnded={() => {
          this.updateVideoUrl()
        }}
        ref={this.setVideoPlayerRef}
        onClick={this.handleClick}
      >
        <source src={`https://monstermusic.tv/video/ash.m4v`} type="video/mp4" />
        {/* <source src={`/video/${this.props.backgroundVideoUrls[this.state.counter].name}`} type="video/mp4" /> */}
      </Video>
    )
  }
}

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators(backgroundVideoActions, dispatch)
// }

// const mapStateToProps = state => {
//   return {
//     items: state.items,
//   }
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(BackgroundVideo)

export default BackgroundVideo
