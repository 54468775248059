const backgroundVideoList = [
  { name: 'ahava.mp4', id: '294102486' },
  { name: 'ash.m4v', id: '264438824' },
  { name: 'audi.mp4', id: '316775543' },
  { name: 'genesis.mp4', id: '190994814' },
  { name: 'hi-well.mp4', id: '319474253' },
  { name: 'pokemon.m4v', id: '259532857' },
  { name: 'shimanehq.m4v', id: '228623606' },
  { name: 'sk11.m4v', id: '302313357' },
]

export default backgroundVideoList
