import React from 'react'

const Footer = ({campaign}) =>{
    return (
      <div className="call-to-action bg-light">
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <h2 className="section-heading">Let's Get In Touch!</h2>
              <hr />
              <p>
                Ready to start your next project with us? That's great! Give us a call or send us an email and we will
                get back to you as soon as possible!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 ml-auto text-center">
              <i className="fa fa-phone fa-3x sr-contact" />
              <p>{campaign.contactNumber}</p>
            </div>
            <div className="col-lg-4 mr-auto text-center">
              <i className="fa fa-envelope-o fa-3x sr-contact" />
              <p>
              <a href={`mailto:${campaign.contactEmail}`}>{campaign.contactEmail}</a>
              </p>
            </div>
          </div>
        </div>
        <div className="container text-center">
          <span>{'\u00A9'} 2020 {campaign.title}</span>
        </div>
      </div>
    )
  
}

export default Footer
