import React from "react";
import $ from "jquery";
import PropTypes from "prop-types";
import { Popup } from "../lib/magnificpopup/Popup";
import Player from "@vimeo/player";

let monsterData = [];

export const Portfolio = ({
  projects,
  parseProjectTitle,
  parseProjectDescription,
}) => {
  if(projects&& projects.projects && projects.projects.items){
    monsterData = projects.projects.items;
  }
 
  return (
    <section className="p-0" id="portfolio">
      <div className="mfp-if-bg" id="iframe-video-background" />
      <div className="container-fluid">
        <div className="row no-gutter popup-gallery">
          {projects.projects &&
            projects.projects.items &&
            projects.projects.items.map(function (project, i) {
              let imageUrl = project.thumbnail_16x19
                ? project.thumbnail_16x19
                : project.thumbnail_large;
              return (
                <Popup
                  className="popup-vimeo"
                  key={i}
                  id={project.vimeoId}
                  data-videoid={i}
                  href={
                    "https://player.vimeo.com/video/" +
                    project.vimeoId +
                    "?playsinline=0"
                  }
                  style={{ width: $(window).width() < 600 ? "100%" : "50%" }}
                  config={popupConfig}
                >
                  <div key={project.id} className="portfolio-box">
                    <img className="img-fluid" alt="thumbnail" src={imageUrl} />
                    <div className="portfolio-box-caption">
                      <div className="portfolio-box-caption-content">
                        <div className="project-name project-title">
                          {parseProjectTitle(project.name)}
                        </div>
                        <div className="project-name project-description">
                          {parseProjectDescription(project.name)}
                        </div>
                        <div className="project-data" />
                      </div>
                    </div>
                  </div>
                </Popup>
              );
            })}
        </div>
      </div>
    </section>
  );
};

const popupConfig = {
  type: "iframe",
  iframe: {
    markup:
      '<div class="mfp-iframe-scaler">' +
      '<div style="z-index:999" class="mfp-close">x</div>' +
      '<iframe class="mfp-iframe" frameborder="0"  allowfullscreen=false>' +
      "</iframe>" +
      '<div class="mfp-title" style="pointer-events: none;">' +
      '<div class="d-none d-md-block mfp-title-custom"></div>' +
      '<p class="d-none d-md-block mfp-title-p"></p>' +
      "</div>" +
      "</div>",
  },
  callbacks: {
    change: function () {
      let iframe = $(this.content).find("iframe");
      let player = new Player(iframe[0], { width: 300, height: 250 });

      let id = $(this.st.el)[0].dataset["videoid"];
      let creditsText = monsterData[id].description;

      creditsText = creditsText.split("\n").join("<br/>");
      let creditsContainer = $(this.content).find(".mfp-title");
      let closeButton = $(this.content).find(".mfp-close");
      let monsterCreditTextParagraph = $(this.content).find(".mfp-title-p");

      $("#iframe-video-background.mfp-if-bg").css("visibility", "visible");
      // progress.css('opacity', '0.0');
      monsterCreditTextParagraph.html(creditsText);
      $(this.content).find(".mfp-title-custom").html(monsterData[id].title);

      $(".vp-target").mousemove(function (e) {
        //console.log('move,,,,,')
      });
      let closeButtonTsetTimeout = null;
      creditsContainer.mousemove(function (e) {
        //console.log('move over the text ....')
        player.getPaused().then(function (paused) {
          if (!paused && closeButton.css("visibility") !== "hidden") {
            clearTimeout(closeButtonTsetTimeout);
            closeButton.show();
            closeButtonTsetTimeout = setTimeout(function () {
              closeButton.hide();
            }, 5000);
          }
        });
      });

      iframe[0].click(function (e) {
        player
          .getPaused()
          .then(function (paused) {
            if (paused) {
              creditsContainer.css("opacity", "0.0");
              player.play();
            } else {
              creditsContainer.css("opacity", "1");
              player.pause();
            }
          })
          .catch(function (error) {
            // an error occurred
            console.log(error);
          });
      });

      player.on("ended", function () {
        Popup.trigger(".element-with-popup", "close");
      });

      player.on("play", function () {
        creditsContainer.css("opacity", "0.0");
        closeButton.hide();
        player.setVolume(1);
        setTimeout(function () {}, 9000);
      });

      player.ready().then(function () {
        player.pause();
        adjustPlayerSize();
      });
      player.on("pause", function () {
        closeButton.show();
        clearTimeout(closeButtonTsetTimeout);
        creditsContainer.show();
        creditsContainer.css("opacity", "1");
      });

      $(window).resize(function () {
        adjustPlayerSize();
      });

      function adjustPlayerSize() {
        let width = player.element.clientWisdth;
        let height = Math.round(width * 0.56);
        player.element.style.height = (height + "px").toString();
      }
    },
    beforeClose: function () {
      $("#iframe-video-background.mfp-if-bg").css("visibility", "hidden");
    },
  },
  mainClass: "mfp-iframe-scaler",
  enableEscapeKey: true,
  showCloseBtn: false, // $(window).width() > 600,
  closeOnBgClick: false,
  fixedContentPos: true,
};

Portfolio.propTypes = {
  parseProjectTitle: PropTypes.func.isRequired,
  parseProjectDescription: PropTypes.func.isRequired,
};
